require('./bootstrap');
try {
    window.$ = window.jQuery = require('jquery');
    var bootstrap = require('bootstrap');
    require('@coreui/coreui');
    require('moment');
    require('datatables.net-bs5')
    require('datatables.net-buttons-bs5');
    require('select2');
    require('@fancyapps/fancybox');
    require('./notify.js');
} catch (error) {
    console.log(error);
}
